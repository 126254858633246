import React, { useState } from 'react';

function AskfordataModal({ show, onConfirm, onCancel, Title, InputText="End of Month Period" }) {
  const [endOfMonthPeriod, setEndOfMonthPeriod] = useState('');

  const handleConfirm = () => {
    onConfirm(endOfMonthPeriod); // Pass the period data to the confirm function
    setEndOfMonthPeriod(''); // Reset input after confirming
  };

  if (!show) {
    return null; // Do not render the modal if show is false
  }

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{Title}</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={onCancel}></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label className="form-label">{InputText}</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter the period (e.g., 'January 2024')"
                value={endOfMonthPeriod}
                onChange={e => setEndOfMonthPeriod(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AskfordataModal;
