export class HttpClientUtil {
  
  /**
   * Performs a GET request to the specified URL.
   * @param url The URL to request.
   */
  static async getRequest(
    url: string,
    params?: Record<string, any>,
    returnerror: boolean = true
  ): Promise<any> {
    try {

      // Append query parameters if provided
      if (params && Object.keys(params).length > 0) {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            queryParams.append(key, String(value));
          }
        });
        
        // Append query string to URL
        url = `${url}${url.includes('?') ? '&' : '?'}${queryParams.toString()}`;
      }
      
      const response = await fetch(url);
      const data = await response.json();
      if (!response.ok) {
        const errorMessage = data.message || "Failed to fetch data";
        if (returnerror) {
          return { success: false, message: errorMessage };
        } else {
          throw new Error(errorMessage);
        }
      }
      return data;
    } catch (error) {
      console.error("GET Request Error:", error);
      throw error;
    }
  }

  /**
   * Performs a POST request to the specified URL with the given payload.
   * @param url The URL to request.
   * @param payload The payload to send in the body of the request.
   * @param returnerror Whether to return the error or throw it.
   */
  static async postRequest(url: string, payload: object, returnerror: boolean = true) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const contentType = response.headers.get("content-type");
      const contentDisposition = response.headers.get("Content-Disposition");
      let data;
  
      if (contentType && contentType.includes("application/json")) {
        data = await response.json();
      } else if (
        contentType && (
          contentType.includes("application/pdf") ||
          contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
          contentType.includes("text/csv"))
      ) {
        data = await response.blob();
  
        let fileName = 'report'; // Default filename
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const matches = contentDisposition.match(/filename="?([^"]+)"?/);
          if (matches && matches[1]) {
            fileName = matches[1];
          }
        } else {
          // Fallback to determine the file extension based on the content type
          const fileExtension = contentType.includes("application/pdf") ? "pdf" :
                                contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ? "xlsx" : "csv";
          fileName = `report.${fileExtension}`;
        }
  
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a); 
  
        return { success: true }; 
      } else {
        data = await response.text(); // Handle other content types
      }
  
      if (!response.ok) {
        const errorMessage = data.message || "Failed to fetch data";
        if (returnerror) {
          return { success: false, message: errorMessage };
        } else {
          throw new Error(errorMessage);
        }
      }
  
      return data; // Return JSON data or plain text data
  
    } catch (error) {
      console.error("POST Request Error:", error);
      if (returnerror) {
        return { success: false, message: error.message };
      } else {
        throw error;
      }
    }
  }

  /**
   * Performs a PUT request to the specified URL with the provided body.
   * @param url The URL to request.
   * @param body The body of the request, typically an object that will be JSON-stringified.
   */
  static async putRequest(
    url: string,
    body: object = {},
    returnerror: boolean = true
  ): Promise<any> {
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (!response.ok) {
        const errorMessage = data.message || "Failed to fetch data";
        if (returnerror) {
          return { success: false, message: errorMessage };
        }
        throw new Error(errorMessage);
      }
      return data;
    } catch (error) {
      console.error("PUT Request Error:", error);
      throw error;
    }
  }

  /**
   * Performs a DELETE request to the specified URL.
   * @param url The URL to request.
   */
  static async deleteRequest(
    url: string,
    body: object = {},
    returnerror: boolean = true
  ): Promise<any> {
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      });
      const data = await response.json();
      if (!response.ok) {
        const errorMessage = data.message || "Failed to fetch data";
        if (returnerror) {
          return { success: false, message: errorMessage };
        }
        throw new Error(errorMessage);
      }
      return data;
    } catch (error) {
      console.error("DELETE Request Error:", error);
      throw error;
    }
  }


  static async handleFileUpload(
    url: string,
    payloadData: object,
    file: File,
    responseBool: boolean = false
  ) {
    const formData = new FormData();
    formData.append('file', file);
  
    for (const [key, value] of Object.entries(payloadData)) {
      formData.append(key, value as string);
    }
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
  
      if (!response.ok || !data.success) {
        console.error("File upload failed:", data.message || "Unknown error.");
        return {
          success: false,
          data: null,
          message: data.message || "File upload failed due to an unknown error.",
        };
      }
  
      if (responseBool) {
        return { success: data.success, data: data.data ?? null,  message: data.message || "Upload successful" };
      }
  
      return { success: data.success, data: data.data ?? null,  message: data.message || "File upload completed successfully." };
    
    } catch (error) {
      console.error("File upload failed:", error);
      return {
        success: false,
        message: "File upload encountered an unexpected error.",
      };
    }
  }

}
