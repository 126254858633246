import { useState } from "react";
import Customer from "../classes/Customer";
import { HttpClientUtil } from "../../components/common/HttpClientUtil.ts";

export default function AdminScreenViewModel(): IAdminScreenViewModel {
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [showConfirmModalEOM, setShowConfirmModalEOM] = useState<boolean>(false);
    const [showConfirmModalRCM, setShowConfirmModalRCM] = useState<boolean>(false);
    const [showErrorDiv, setShowResponseDiv] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);
    const [showEndOfMonthModal, setShowEndOfMonthModal] = useState<boolean>(false);
    const [showRecalculateCustomerMinutesModal, setRecalculateCustomerMinutesModal] = useState<boolean>(false);

    const handleEndOfMonthPrompt = (): void => {
        setShowConfirmModalEOM(true);
    };


    const resetResponseModal = (): void => {
        setIsSuccess(false);
        setShowResponseDiv(false);
        setResponseMessage("");
    }
    const recalculateCustomerMinutes = async (data: string | null): Promise<void> => {
        resetResponseModal();

        
        if (!data) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        const date = new Date(data);
        if (isNaN(date.getTime())) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        setRecalculateCustomerMinutesModal(false);
        setShowConfirmModalRCM(false);
        setShowLoadingScreen(true);

        const url = `/run/repair-customer-minutes`;
        try {
            const request = { date };
            const response = await HttpClientUtil.postRequest(url, request);

            setShowResponseDiv(true);
            setIsSuccess(response.success);
            setResponseMessage(response.message);
        } catch (error) {
            console.error("Failed to recalculate customer minutes:", error);
            setShowResponseDiv(true);
            setResponseMessage("An error occurred while processing your request.");
        } finally {
            setShowLoadingScreen(false);
        }
    }

    const endOfMonth = async (data: string | null): Promise<void> => {
        resetResponseModal();       
        if (!data) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        const date = new Date(data);
        if (isNaN(date.getTime())) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        setShowEndOfMonthModal(false);
        setShowConfirmModalEOM(false);
        setShowLoadingScreen(true);

        const url = `/run/end-of-month`;
        try {
            const request = { date };
            const response = await HttpClientUtil.postRequest(url, request);

            setShowResponseDiv(true);
            setIsSuccess(response.success);
            setResponseMessage(response.message);
        } catch (error) {
            console.error("Failed to run end of month process:", error);
            setShowResponseDiv(true);
            setResponseMessage("An error occurred while processing your request.");
        } finally {
            setShowLoadingScreen(false);
        }
    };

    const handleConfirmEOM = (): void => {
        setShowConfirmModalEOM(false);
        setShowEndOfMonthModal(true);
    };

    const handleConfirmRCM = (): void => {
        setShowConfirmModalRCM(false);
        setRecalculateCustomerMinutesModal(true);
    };
    return {
        customers,
        setCustomers,
        showConfirmModalEOM,
        showConfirmModalRCM,
        setShowConfirmModalEOM,
        setShowConfirmModalRCM,
        showErrorDiv,
        setShowResponseDiv,
        responseMessage,
        setResponseMessage,
        isSuccess,
        setIsSuccess,
        showLoadingScreen,
        setShowLoadingScreen,
        showEndOfMonthModal,
        setShowEndOfMonthModal,
        handleEndOfMonthPrompt,
        endOfMonth,
        handleConfirmEOM,
        handleConfirmRCM,
        showRecalculateCustomerMinutesModal,
        setRecalculateCustomerMinutesModal,
        recalculateCustomerMinutes
    };
}

export interface IAdminScreenViewModel {
    customers: Array<Customer>;
    setCustomers: (customers: Array<Customer>) => void;
    showConfirmModalEOM: boolean;
    showConfirmModalRCM: boolean;
    setShowConfirmModalEOM: (show: boolean) => void;
    setShowConfirmModalRCM: (show: boolean) => void;
    showErrorDiv: boolean;
    setShowResponseDiv: (show: boolean) => void;
    responseMessage: string;
    setResponseMessage: (message: string) => void;
    isSuccess: boolean;
    setIsSuccess: (success: boolean) => void;
    showLoadingScreen: boolean;
    setShowLoadingScreen: (show: boolean) => void;
    showEndOfMonthModal: boolean;
    setShowEndOfMonthModal: (show: boolean) => void;
    handleEndOfMonthPrompt: () => void;
    endOfMonth: (data: string | null) => Promise<void>;
    handleConfirmEOM: () => void;
    handleConfirmRCM: () => void;
    recalculateCustomerMinutes: (data: string | null) => Promise<void>;
    showRecalculateCustomerMinutesModal: boolean;
    setRecalculateCustomerMinutesModal: (show: boolean) => void;
}