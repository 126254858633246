import { useState } from "react";
import Customer from "../classes/Customer";
import ArchivedLog from "../classes/ArchivedLog.ts";
import { HttpClientUtil } from "../../components/common/HttpClientUtil.ts";

export default function RecentCustomerLogsViewModel(): IRecentLogViewModel {
    const [logs, setLogs] = useState<Array<ArchivedLog>>([]);
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState<Customer | null>(null);

    // Function to fetch customer logs from the API
    const fetchCustomerLogs = async (customer: Customer, error: (msg: string) => void) => {
        setLoading(true);

        if (!customer) {
            setLoading(false);
            return;
        }

        const dialcode = customer.code;

        try {
            const response = await HttpClientUtil.getRequest(`/api/transactions/recentlogs`, 
                {
                    cust: dialcode,
                }
            );
            if (!response.success) {
                error("Failed to fetch logs");
                return;
            }

            let logs = response.data.map((log) => new ArchivedLog(log));
            logs = logs.sort((a, b) => {
                const dateA = new Date(a.displayDate());
                const dateB = new Date(b.displayDate());
                return dateB.getTime() - dateA.getTime(); // Sort by newest first
            });

            setLogs(logs);
        } catch (err) {
            error("Failed to fetch logs");
        } finally {
            setLoading(false);
        }
    };

    const show = (customer: Customer) => {
        setCustomer(customer);
    };

    const close = () => {
        setCustomer(null);
    };

    return {
        customer,
        fetchCustomerLogs,
        logs,
        loading,
        show,
        close,
    };
}

export interface IRecentLogViewModel {
    customer: Customer | null;
    fetchCustomerLogs: (customer: Customer, error: (msg: string) => void) => void;
    logs: Array<ArchivedLog>;
    loading: boolean;
    show: (customer: Customer) => void;
    close: () => void;
}