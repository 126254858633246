import React, { ReactElement, useState, KeyboardEvent, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import Customer from '../js/classes/Customer.ts';

export default function Autocomplete({
    id = "",
    name = "",
    label = "",
	labelKey = "label", // Update this to match the custom label property in options
    autoFocus = false,
    forceSelection = true,
    datalist = [],
    placeholder = "",
    setValue,
    clearOnComplete = false,
    defaultValue = undefined
}: IAutocomplete) {
    const [singleSelections, setSingleSelections] = useState<Array<Customer>>(defaultValue ? [defaultValue] : []);
    const [filteredList, setFilteredList] = useState<Array<Customer>>(datalist);
    const [navigating, setIsNavigating] = useState<boolean>(false);
    const [filterText, setFilterText] = useState('');
    const typeaheadRef = useRef(null); 
    
    const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {

        /* 
        why this exists the boostrap autoselect only works for partial matches on 
        enter not full matches.  This is a workaround to allow for full matches to be selected 
        */
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setIsNavigating(true);
        } else if (e.key === 'Enter') {



            const exactMatch = filteredList.find(f => f.code.toLowerCase() === filterText || f.name.toLowerCase() === filterText);


            if (filteredList.length > 1) {
                
                if (navigating) {
                    // we are navigating 
                    // so we don't want to "make things get selected"
                    return;
                }
                if (!exactMatch) {
                    // if we are not navigating, and we don't have an exact match
                    // , then we want to select the first item
                    addNewLog(filteredList[0]);
                    return;
                } 

                const selection = exactMatch || filteredList[0];
                addNewLog(selection);
            }
        }else {
            setIsNavigating(false);
        }
    };

    const onSelect = (newSelections: Array<{ label: string, value: Customer }>) => {
        setFilterText(''); // Clear the text filter
        if (newSelections.length > 0) {
            addNewLog(newSelections[0].value);
        } else {
            setSingleSelections([]);
            //default to true to force a valid customer
            //allows customers to be unselected without the clearonComplete option

            if (!forceSelection) {
                setValue(Customer.createEmpty());
            }
        }
        setIsNavigating(false);
    };

    const addNewLog = (cust: Customer) => {
        setValue(cust);
        if (clearOnComplete) {
            setSingleSelections([]); // Clear the selection
            setFilterText('');       // Clear the text filter
            setFilteredList([]);     // Reset the filtered list

            // Clear the Typeahead input field explicitly
            if (typeaheadRef.current) {
                typeaheadRef.current.clear();
            }
        } else {
            setSingleSelections([cust]);  // Set the selected customer
        }
    };

    const onInputChange = (text: string) => {
        const lowerText = text.toLowerCase();
        setFilterText(lowerText);
        const filtered = datalist.filter(i => i.name.toLowerCase().includes(lowerText) || i.code.toLowerCase().includes(lowerText));
        setFilteredList(filtered);
    };

    let span: ReactElement;
    let divClass = "input-group mb-3";
    if (name.length > 0) {
        span = (<span className="input-group-text">{name}</span>);
    } else if (label.length > 0) {
        divClass = "mb-3";
        span = (<label className="small">{label}</label>);
    } else {
        span = (<></>);
    }

    return (
        <div className={divClass}>
            {span}
            <Typeahead
                id={id}
                labelKey="label"
                options={filteredList.map(item => ({
                    label: `${item.code} - ${item.name}`,
                    value: item
                }))}
                placeholder={placeholder}
                selected={singleSelections.map(item => ({
                    label: `${item.code} - ${item.name}`,
                    value: item
                }))}
                autoFocus={autoFocus}
                forceSelection={forceSelection}
                onKeyDown={onKeyPress}
                onChange={onSelect}
                onInputChange={onInputChange}
                highlightOnlyResult={true}
                minLength={1}
                ref={typeaheadRef} // Attach the ref
            />
        </div>
    );
}
interface IAutocomplete {
    id?: string;
    name?: string;
    label?: string;
    labelKey?: string;
    autoFocus?: boolean;
    forceSelection?: boolean;
    datalist?: Array<Customer>;
    placeholder?: string;
    setValue: (newValue: Customer | null) => void;
    clearOnComplete?: boolean;
    defaultValue?: Customer;
    selected?: Customer;  
}
