import React, { useState, useEffect } from "react";
import ErrorDiv from "../../common/ErrorModal.jsx";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import CommentModal from "./CommentsModal";
import ConfirmationModal from "../../common/ConfirmationModal.jsx";
import CustomerSettingsModal from "../Modals/customerSettings.tsx";
export const CommentsForm = ({ customerDetails }) => {
  const [comments, setComments] = useState(customerDetails.comments || []);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentComment, setCurrentComment] = useState(null);
  const [isSuccess, setisSuccess] = useState(true);
  const [responseMessage, setresponseMessage] = useState(true)
  const [showErrorDiv, setShowResponseDiv] = useState(false)

  useEffect(() => {
    setComments(customerDetails.comments || []);
  }, [customerDetails]);

  const handleEdit = (comment) => {
    setCurrentComment(comment);
    setShowModal(true);
  };

  const handleDelete = (comment) => {
    setCurrentComment(comment);
    setShowConfirmModal(true);
  };

  const handleResponse = (response) => {

    if (!response || Object.keys(response).length === 0) {
      // assume nothing happened
      return;
    }
    setShowResponseDiv(true);
    
    if (response.success){
      handleSuccessEvent()
    } else {
      handleFailedEvent(response.message);
    }
  }

  const handleSuccessEvent = () => {
    setisSuccess(true);
    setresponseMessage('');
  }

  
  const handleFailedEvent = (message) => {
    setisSuccess(false);
    setresponseMessage(message);
  }
  const confirmDelete = async () => {
    const url = `/api/deletecomments/${currentComment.C_ID}`;
    try {
      const response = await HttpClientUtil.postRequest(url, currentComment);
      handleResponse(response);
      
      if (!response.success) {
        setShowConfirmModal(false);
        throw new Error(response.error);
      }
      
      const updatedComments = comments.filter(
        (c) => c.C_ID !== currentComment.C_ID
      );
      setComments(updatedComments);
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Failed to delete the comment:", error);
    }
  };

  const handleAddNew = () => {
    setCurrentComment({
      C_TYPE: "",
      C_COMMENT: "",
      C_DIALCODE: customerDetails.C_DIALCODE,
    });
    setShowModal(true);
  };

  const pushComment = (response, deleteevent) => {  

    //we deleted a comment template
    handleResponse(response);
    if (!response.data || deleteevent)
        return;

    let comment = response.data;
    let updatedComments = [...comments, comment];
    setComments(updatedComments);
  }
  const saveComment = async (comment, bool = false) => {
    
    //bool determines if we are saving template
    
    const url = `/api/comments${comment.C_ID ? `/${comment.C_ID}` : ""}`;
    
    let savetemplate = bool ? bool: false;
    //add to data
    comment.savetemplate = savetemplate
    try {
      
      const response = await HttpClientUtil.postRequest(url, comment);
      handleResponse(response);
      if (!response.success) {
        throw new Error(response.error);
      }

      let updatedComments = comment.C_ID
        ? comments.map((c) => (c.C_ID === comment.C_ID ? response.data : c))
        : [...comments, response.data];
      setComments(updatedComments);
      setShowModal(false);

    } catch (error) {
      console.error("Failed to save the comment:", error);
    }
  };
  return (
    <div>
        <ErrorDiv
        success={isSuccess}
        errorMessage={responseMessage}
        isVisible={showErrorDiv}
        onClose={() => setShowResponseDiv(false)}
      />
      <h3 className="mb-4">Settings</h3>
      <button className="btn btn-primary mb-3" onClick={handleAddNew}>
        New Setting
      </button>
      <CustomerSettingsModal
        custCode={customerDetails.C_DIALCODE} 
        callback={pushComment}
      />
      {comments.filter(comment => comment.C_COMMENT).length > 0 ? (
        comments.filter(comment => comment.C_COMMENT).map((comment, index) => (
          <div className="row mb-2" key={comment.C_ID || index}>
            <div className="col" style={{ marginTop: "10px", marginBottom: "10px" }}>
              <strong>{comment.C_TYPE}</strong>: {comment.C_COMMENT}
            </div>
            <div className="col-auto">
              <button
                className="btn btn-secondary"
                onClick={() => handleEdit(comment)}
              >
                Edit
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-danger"
                onClick={() => handleDelete(comment)}
              >
                Delete
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No comments available.</p>
      )}
      <CommentModal
        showModal={showModal}
        setShowModal={setShowModal}
        comment={currentComment}
        saveComment={saveComment}
      />
      <ConfirmationModal
        show={showConfirmModal}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmModal(false)}
        title="Confirm Deletion"
        message="Are you sure you want to delete this comment?"
      />
    </div>
  );
}  

export default CommentsForm;
